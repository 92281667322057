import api from '@/utils/api'

export function propList(params) {
  return api.get('activity/v1/redpacket/props', { params:params })
}

export function drawableTimes(params) {
  return api.get('activity/v1/redpacket/drawableTimes', { params:params })
}

export function draw(params) {
  return api.post('activity/v1/redpacket/draw', params)
}

export function drawFinally(params) {
  return api.post('activity/v1/redpacket/drawFinally', params)
}

export function rank(params) {
  return api.get('activity/v1/redpacket/rank', { params:params })
}

export function userProps(params) {
  return api.get('activity/v1/redpacket/userProps', { params:params })
}

export function redpacket(params) {
  return api.get('activity/v1/redpacket', { params:params })
}
