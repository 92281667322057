<template>
  <div class="warpbg">
    <div class="user_head">
      
      <div class="btn_login" v-if="!isLogin"  @click="showLoginDialog = true">登录</div>
      <div class="btn_login" v-if="isLogin" @click="handleLogout">退出</div>
      <div class="server_role_text" v-if="isLogin && roleId">欢迎您 {{ serverName }} {{ roleName }}</div>
    </div>
    
    <div class="activity_name">{{ activity.name }}</div>
    <div class="box_rank">
      <div class="cell_rank_1" v-if="rankList[0]">
        <img class="cell_rank_1_avatar" :src="rankList[0].user.avatar" alt="">
        <div class="cell_rank_1_server">{{ rankList[0].role.server }}</div>
        <div class="cell_rank_1_role">{{ rankList[0].role.role_name }}</div>
        <!-- <div class="cell_rank_1_comment">{{ rankList[0].comment | filterComment}}</div> -->
        <vue-seamless-scroll
          :data="[rankList[0].comment]"
          :class-option="classOption"
          class="cell_rank_1_comment_warp"
        >
          <div class="cell_rank_1_comment" v-for="(item, index) in [rankList[0].comment]" :key="index">{{ item | filterComment }} </div>
        </vue-seamless-scroll>
      </div>

      <div class="cell_rank_2-5" :class="{ 'cell_rank_dan': index%2===1, 'cell_rank_4-5': index>1 }" v-for="(item, index) in rankList.slice(1, 5)" :key="item.id">
        <img class="cell_rank_2-5_avatar" :src="item.user.avatar" alt="">
        <div class="cell_rank_2-5_server">{{ item.role.server }}</div>
        <div class="cell_rank_2-5_role">{{ item.role.role_name }}</div>
        <!-- <div class="cell_rank_2-5_comment">{{ item.comment | filterComment }}</div> -->
        <vue-seamless-scroll
          :data="[item.comment]"
          :class-option="classOption"
          class="cell_rank_2-5_comment_warp"
        >
          <div class="cell_rank_2-5_comment" v-for="(item, index) in [item.comment]" :key="index">{{ item | filterComment }} </div>
        </vue-seamless-scroll>
      </div>

      <!-- <div class="cell_rank_2-5 cell_rank_dan">
        <img class="cell_rank_2-5_avatar" src="" alt="">
        <div class="cell_rank_2-5_server">雪花41区</div>
        <div class="cell_rank_2-5_role">名字太长不被杀</div>
        <div class="cell_rank_2-5_comment">只需体验五分钟</div>
      </div>
      <div class="cell_rank_2-5 cell_rank_4-5">
        <img class="cell_rank_2-5_avatar" src="" alt="">
        <div class="cell_rank_2-5_server">雪花41区</div>
        <div class="cell_rank_2-5_role">名字太长不被杀</div>
        <div class="cell_rank_2-5_comment">只需体验五分钟</div>
      </div>
      <div class="cell_rank_2-5 cell_rank_4-5 cell_rank_dan">
        <img class="cell_rank_2-5_avatar" src="" alt="">
        <div class="cell_rank_2-5_server">雪花41区</div>
        <div class="cell_rank_2-5_role">名字太长不被杀</div>
        <div class="cell_rank_2-5_comment">只需体验五分钟</div>
      </div> -->
      
    </div>
    <!-- 奖池 -->
    <div class="frame_redpacket">
      <!-- 上部横排 -->
      <div class="box_prop_top">
        <!-- 上部单个 -->
        <div class="cell_prop_h" :class="{ 'prop_drew': item.drawable===0, 'cell_prop_h_last': index===2 }" v-for="(item, index) in propList.slice(0, 3)" :key="item.id">
          <img class="prop_img" :src="item.prop_img" alt="">
          <div class="prop_name_right">{{ item.prop_name }}</div>
        </div>
        <!-- <div class="cell_prop_h">
          <img class="prop_img" :src="require('@/assets/activity/redpacket/prop_img.png')" alt="">
        </div>
        <div class="cell_prop_h">
          <img class="prop_img" :src="require('@/assets/activity/redpacket/prop_img.png')" alt="">
        </div> -->
      </div>

      <div class="box_redpacket_mid">

        <!-- 左部竖排 -->
        <div class="box_prop_left">
          <div class="cell_prop_v" :class="{ 'prop_drew': item.drawable===0, 'cell_prop_h_last': index===2 }" v-for="(item, index) in propList.slice(8, 10).reverse()" :key="item.id">
            <img class="prop_img" :src="item.prop_img" alt="">
            <div class="prop_name_right">{{ item.prop_name }}</div>
          </div>
          <!-- <div class="cell_prop_v">
            <img class="prop_img" :src="require('@/assets/activity/redpacket/prop_img.png')" alt="">
          </div> -->
        </div>

        <div class="box_redpacket_draw">

          <!-- 红包 -->
          <div class="box_redpacket" @click="handleDraw"></div>

          <!-- 可抽取显示 -->
          <div class="box_drawable">
            <div class="box_drawable_count">
              <div class="box_drawable_count_text">可抽次数:{{ drawableTimes }}</div>
            </div>
            <div class="btn_refresh_drawable_times" @click="handleRefreshDrawableTimes"></div>
          </div>
        </div>

        <!-- 右部竖排 -->
        <div class="box_prop_right">
          <div class="cell_prop_v" :class="{ 'prop_drew': item.drawable===0, 'cell_prop_h_last': index===2 }" v-for="(item, index) in propList.slice(3, 5)" :key="item.id">
            <img class="prop_img" :src="item.prop_img" alt="">
            <div class="prop_name_right">{{ item.prop_name }}</div>
          </div>
          <!-- <div class="cell_prop_v">
            <img class="prop_img" :src="require('@/assets/activity/redpacket/prop_img.png')" alt="">
          </div> -->
        </div>
      </div>

      <!-- 下部横排 -->
      <div class="box_prop_bottom">
        <div class="cell_prop_h" :class="{ 'prop_drew': item.drawable===0, 'cell_prop_h_last': index===2 }" v-for="(item, index) in propList.slice(5, 8).reverse()" :key="item.id">
          <img class="prop_img" :src="item.prop_img" alt="">
          <div class="prop_name_right">{{ item.prop_name }}</div>
        </div>
        <!-- <div class="cell_prop_h">
          <img class="prop_img" :src="require('@/assets/activity/redpacket/prop_img.png')" alt="">
        </div>
        <div class="cell_prop_h">
          <img class="prop_img" :src="require('@/assets/activity/redpacket/prop_img.png')" alt="">
        </div> -->
      </div>

      <!-- 已抽中 -->
      <div class="frame_drew" v-show="showDrewFrame">
        <div class="box_drew_prop">
          <img class="drew_prop_img" :src="drewProp.redpacket_prop_img" alt="">
          <div class="drew_prop_name">{{ drewProp.redpacket_prop_name }}</div>
        </div>
        <div class="box_drew_cdkey">
          <div class="drew_cdkey_text">{{ drewProp.cdkey }}</div>
          <div class="drew_cdkey_btn_copy" :data-clipboard-text="drewProp.cdkey" @click="handleCopyCdkey"></div>
        </div>
        <div class="box_drew_confirm" @click="showDrewFrame=false"></div>
      </div>
    </div>

    <!-- 最终奖励 -->
    <div class="frame_last_prop">
      <div class="box_last_prop" :class="{ 'prop_drew': finallyProp.drawable===0}">
        <img :src="finallyProp.prop_img" class="cell_last_prop_img" />
        <div class="cell_last_prop_name">{{ finallyProp.prop_name }}</div>
      </div>
      <div class="box_last_prop_confirm" @click="handleDrawFinally"></div>
      <div class="box_last_prop_drew_count">已抽次数:{{ drewTimes }}次</div>
    </div>

    <!-- 用户已获得奖励 -->
    <div class="frame_user_prop">
      <!-- 奖励列表 -->
      <div class="box_user_prop">
        <div class="cell_user_prop" v-for="(item, index) in userPropList" :key="index">
          <div class="cell_user_prop_name">{{ item.redpacket_prop_name }}</div>
          <van-button type="default" class="cell_user_prop_btn_show" :data-cdkey="item.cdkey" @click="handleShowUserPropCdkeyFrame">查看</van-button>
        </div>
        <!-- <div class="cell_user_prop">
          <div class="cell_user_prop_name">材料自选礼包(五)</div>
          <van-button type="default" class="cell_user_prop_btn_show">查看</van-button>
        </div>
        <div class="cell_user_prop">
          <div class="cell_user_prop_name">材料自选礼包(五)</div>
          <van-button type="default" class="cell_user_prop_btn_show">查看</van-button>
        </div> -->
      </div>
      <!-- 查看CDKEY弹窗 -->
      <div class="frame_user_prop_cdkey" v-show="showUserPropCdkeyFrame">
        <div class="box_cdkey">
          <div class="cell_cdkey_text">{{ userPropCdkey }}</div>
          <div class="cell_cdkey_btn_copy" :data-clipboard-text="userPropCdkey" @click="handleUserPropCopyCdkey"></div>
        </div>
        <div class="box_cdkey_btn_confirm" @click="handleCloseUserPropCdkeyFrame"></div>
      </div>
    </div>

    <div class="frame_gift">
      <div
        class="box_gift"
        v-for="(item, index) in giftList"
        :key="index"
        v-show="index >= 0 && index <= 4"
      >
        <div
          class="cell_gift"
          v-for="(gift, i) in item"
          :key="i"
          v-show="i >= 0 && i <= 4"
        >
          <img class="cell_gift_prop_img" :src="gift.prop_img">
          
          <div class="cell_gift_prop_name">{{ gift.prop_name }}</div>
        </div>
      </div>
    </div>

    <!-- 登录 -->
    <van-dialog class="login_dialog" v-model="showLoginDialog" title="玩家登录" confirm-button-text="登录" theme="round-button" 
      confirm-button-color="linear-gradient(to right, #ffa800, #ff3600)" :before-close="handleLoginDialogBeforeClose">
      <div class="van-icon van-icon-close van-popup__close-icon van-popup__close-icon--top-right" @click="handleLoginDialogClose"></div>

      <van-form ref="loginForm">
        <van-field v-model="username" label="账号" placeholder="填写账号" :rules="[{ required: true }]" />
        <van-field v-model="password" type="password" label="密码" placeholder="填写密码" :rules="[{ required: true }]"  />

      </van-form>
    </van-dialog>

    <!-- 选择区服 -->
    <van-dialog class="server_role_dialog" confirm-button-color="linear-gradient(to right, #ffa800, #ff3600)" theme="round-button"
      v-model="showServerRoleDialog" confirm-button-text="确认" :close-on-click-overlay="true" :before-close="handleServerRoleDialogBeforeClose">
      <van-form >

        <van-field
          readonly
          clickable
          name="picker"
          label="游戏区服"
          placeholder="点击选择区服"
          :value="serverName"
          @click="showServerPicker = true"
          :rules="[{ required: true }]"
        />
        <van-popup v-model="showServerPicker" position="bottom" get-container="body">
          <van-picker
            show-toolbar
            :columns="serverColumns"
            @confirm="onServerConfirm"
            @cancel="showServerPicker = false"
            :loading="serverListLoading"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          name="picker"
          label="角色名"
          placeholder="点击选择角色"
          :value="roleName"
          @click="showRolePicker = true"
          :rules="[{ required: true }]"
        />

        <van-popup v-model="showRolePicker" position="bottom" get-container="body">
          <van-picker
            show-toolbar
            :columns="roleColumns"
            @confirm="onRoleConfirm"
            @cancel="showRolePicker = false"
            :loading="roleListLoading"
          />
        </van-popup>
      </van-form>
    </van-dialog>

    <div class="box_desc">
      <div class="cell_desc_title">活动说明:</div>
      <div v-html="activity.desc" class="cell_desc_text"></div>
    </div>
    
  </div>
</template>

<script>
import { Popup } from 'vant'
import { webLogin, webLogout } from "@/api/login"
import { serverList, roleList } from "@/api/user"
import { propList, drawableTimes, draw, rank, userProps, redpacket, drawFinally } from "@/api/redpacket"
import { checkAuth, setUsername, getUsername, removeToken } from "@/utils/auth"
import { getUser, setUser, removeUser } from "@/utils/user"
import Clipboard from 'clipboard'
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  components: {
    vanPopup: Popup, vueSeamlessScroll
  },
  filters: {
    filterComment: function (value) {
      let data = value
      if (!data) {
        data = '是兄弟就来看我'
      }
      return data
    }
  },
  data () {
    return {
      parentGameId: undefined,
      isLogin: false,
      // 登录后显示的用户名
      account: undefined,
      showLoginDialog: false,
      username: undefined,
      password: undefined,
      // Rank 排名
      rankList: [],
      // 区服角色框
      showServerRoleDialog: false,
      // 区服
      showServerPicker: false,
      serverListLoading: true,
      serverColumns: [],
      serverList: undefined,
      serverName: undefined,
      // 角色
      showRolePicker:false,
      roleListLoading: true,
      roleColumns: [],
      roleList: undefined,
      roleName: undefined,
      roleId: undefined,
      // 道具
      propList: [],
      finallyProp: { prop_name: undefined, prop_img: undefined, drawable: 1 },
      // 已抽中框
      showDrewFrame: false,
      // 抽中道具
      drewProp: { redpacket_prop_img: undefined, redpacket_prop_name: undefined, cdkey: undefined },
      // 可抽次数
      drawableTimes: 0,
      // 已抽次数
      drewTimes: 0,
      // 抽中道具列表
      userPropList: [],
      // 用户已获得奖励CDKEK弹框
      showUserPropCdkeyFrame: false,
      userPropCdkey: undefined,
      // 
      giftList: [],
      activity: { name: undefined, desc: undefined },
      classOption: { limitMoveNum: 1, direction: 2, step: 0.5 }
    }
  },
  created () {
    this.initView()
    // 游戏
    this.parentGameId = this.$route.query.game_id
    console.log(this.parentGameId)

    // this.getRankList()
    this.getActivity()

    this.initUser()
    this.getUserPropList() // 需要在 initUser 初始化用户信息后调用
    this.getPropList()  // 需要在 initUser 初始化用户信息后调用
  },
  methods: {
    initView () {
      let scale = 1.0 / window.devicePixelRatio;
      let text = `<meta name="viewport" content="width=device-width, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}, user-scalable=no">`;
      document.write(text);
      document.documentElement.style.fontSize = window.innerWidth / 7.5 + "px";
    },
    // 获取排名列表
    getRankList() {
      rank({ game_id: this.parentGameId }).then(response => {
        this.rankList = response
        console.log(response)
      })
    },
    getUserPropList() {
      userProps({ game_id: this.parentGameId, role_id: this.roleId }).then(response => {
        this.userPropList = response
        console.log(response)
      })
    },
    initUser() {
      // 登录框用户名显示上次的用户名
      this.username = getUsername()
      // 登录状态
      this.isLogin = checkAuth()
      if (this.isLogin) {
        this.account = getUsername()
        console.log(this.account)
        if (!getUser()) {
          this.reqServerList()
          // this.showServerRoleDialog = true
        } else {
          const redpacketUser = getUser()
          this.serverName = redpacketUser.serverName
          this.roleId = redpacketUser.roleId
          this.roleName = redpacketUser.roleName
        }
        this.getDrawableTimes()
      }
    },
    handleLoginDialogBeforeClose(action, done) {
      if (action === 'confirm') {
        // 验证表单
        this.$refs['loginForm'].validate().then(() => {
          // 调用登录方法
          this.loginSubmit(done)
        }).catch(() => {
          done(false)
        })
      } else {
        done()
      }
    },
    handleLoginDialogClose() {
      this.showLoginDialog = false
    },
    // 登录
    loginSubmit (done) {
      webLogin({ username: this.username, password: this.password }).then(response => {
        console.log(response)
        setUsername(response.account)
        // 登录成功
        this.isLogin = true
        done()
        this.showLoginDialog = false
        // 显示区服选择框
        this.showServerRoleDialog = true
        // 加载区服列表
        this.reqServerList()
      }).catch(() => {
        done(false)
      })
    },
    // 退出
    handleLogout () {
      webLogout().then(response => {
        console.log(response)
      })
      this.isLogin = false
      removeToken()
      removeUser()
      this.roleName = undefined
      this.serverName = undefined
      this.roleId = undefined
      // this.$router.go(0)
      location.reload()
    },
    // 确认选择区服
    onServerConfirm(value) {
      this.serverName = value
      // 关闭区服选择器
      this.showServerPicker = false
      // 加载区服下角色
      this.reqRoleList()
    },
    // 确认选择角色
    onRoleConfirm(value, index) {
      this.roleName = value // 角色名
      this.roleId = this.roleList[index]['id'] // 角色ID
      console.log('roleId', this.roleId)
      this.showRolePicker = false
    },
    // 区服角色确定
    handleServerRoleDialogBeforeClose(action, done) {
      if (action === 'confirm') {
        
        if (!this.serverName || !this.roleId) {
          this.$toast.fail('选择区服角色')
          done(false)
        } else {
          // localStorage.redpacket_user = JSON.stringify({ serverName: this.serverName, roleId: this.roleId,  roleName: this.roleName})
          setUser({ serverName: this.serverName, roleId: this.roleId,  roleName: this.roleName})
          done()
          // this.$router.go(0)
          location.reload()
        }
      } else {
        done()
      }
      
    },
    // 请求区服列表
    reqServerList() {
      this.serverColumns = []
      serverList({ game_id: this.parentGameId }).then(response => {
          this.serverListLoading = false
          this.serverList = response
          response.forEach(element => {
            this.serverColumns.push(element.server)
          })
        })
    },
    // 请求角色列表
    reqRoleList() {
      this.roleColumns = []
      roleList({ game_id: this.parentGameId, server: this.serverName }).then(response => {
        this.roleListLoading = false
        this.roleList = response
        response.forEach(element => {
          this.roleColumns.push(element.role_name)
        })
      })
    },
    // 获取最近一轮道具
    getPropList(msg) {
      if (msg) {
        this.$toast.loading({ duration: 0, forbidClick: true, message: msg})
      }
      propList({ game_id: this.parentGameId, role_id: this.roleId }).then(response => {
        this.propList = response.props
        this.finallyProp = response.finally_prop
        if (msg) {
          this.$toast.clear()
        }
        // console.log(this.propList)
      })
    },
    // 刷新抽奖次数
    handleRefreshDrawableTimes () {
      if (this.checkLogin()) {
        this.$toast.loading({ duration: 0, forbidClick: true, message: '刷新次数'})
        this.getDrawableTimes(this.$toast)
      }
    },
    getDrawableTimes (toast) {
      drawableTimes({ game_id: this.parentGameId, role_id: this.roleId }).then(response => {
        this.drawableTimes = response.times
        this.drewTimes = response.drew_times
        if (toast) {
          toast.clear()
        }
      })
    },
    // 红包抽奖
    handleDraw() {
      // TODO 一轮抽完等待更新一轮道具再抽
      if (this.checkLogin()) {
        this.$toast.loading({ duration: 2000, forbidClick: true, message: '抽取中'})
        draw({ game_id: this.parentGameId, role_id: this.roleId }).then(response => {
          this.drewProp = response
          if (!response.cdkey) {
            this.drewProp.cdkey = '活动结束后游戏内邮件领取'
          }
          this.updatePropDrawable(response.redpacket_prop_id)
          this.$toast.clear()
          this.showDrewFrame = true
          this.drawableTimes--
          this.drewTimes++
          // 更新用户已获得奖励列表
          this.userPropList.unshift({ redpacket_prop_name: response.redpacket_prop_name, cdkey: response.cdkey })
        })
      }
      
    },
    // 复制CDKEY
    handleCopyCdkey () {
      let clipboard = new Clipboard('.drew_cdkey_btn_copy')
      clipboard.on('success', e => {
        this.$toast.success('复制成功')
        e.clearSelection()
        clipboard.destroy() // 释放内存
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$toast.fail('该浏览器不支持自动复制')
        e.clearSelection()
        clipboard.destroy()
      })
    },
    // 领取最终道具
    handleDrawFinally() {
      if (this.checkLogin()) {
        if (this.finallyProp.drawable === 1) {
          this.$toast.loading({ duration: 0, forbidClick: true, message: '抽取中'})
          drawFinally({ game_id: this.parentGameId, role_id: this.roleId }).then(response => {
            this.$toast.clear()
            this.$toast.success('领取成功')
            this.finallyProp.drawable = 0
            // 更新用户已获得奖励列表
          this.userPropList.unshift({ redpacket_prop_name: response.redpacket_prop_name, cdkey: undefined })
          })
        } else {
          this.$toast.fail('已领取')
        }
      }
      
    },
    // 更新道具不可抽显示
    updatePropDrawable(propId) {
      console.log('propId', propId)
      let drew_count = 0
      this.propList.forEach((item, index) => {
        if (item.id === propId) {
          this.propList[index].drawable = 0
        }
        if (parseInt(this.propList[index].drawable) === 0) {
          drew_count++
        }
      }, drew_count)
      console.log('drew_count', drew_count)
      // 如果一轮全部抽完,更新道具
      if (drew_count >= 10) {
        this.getPropList('加载新一轮奖励')
      }
    },
    // 显示用户已获得奖励CDKEY
    handleShowUserPropCdkeyFrame(e) {
      this.userPropCdkey = e.target.dataset.cdkey
      if (!this.userPropCdkey) {
        this.$toast.fail('活动结束后游戏内邮件领取')
      } else {
        this.showUserPropCdkeyFrame = true
      }
    },
    handleCloseUserPropCdkeyFrame() {
      this.userPropCdkey = undefined
      this.showUserPropCdkeyFrame = false
    },
    // 复制用户已获得奖励列表CDKEY
    handleUserPropCopyCdkey() {
      let clipboard = new Clipboard('.cell_cdkey_btn_copy')
      clipboard.on('success', e => {
        this.$toast.success('复制成功')
        e.clearSelection()
        clipboard.destroy() // 释放内存
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$toast.fail('该浏览器不支持自动复制')
        e.clearSelection()
        clipboard.destroy()
      })
    },
    getActivity() {
      redpacket({ game_id: this.parentGameId }).then(response => {
        console.log(response)
        this.rankList = response.rank
        this.giftList = response.gifts
        if (response.activity) {
          this.activity = response.activity
          if (response.activity) {
            this.activity.desc = '<p>' + this.activity.desc.replace(/\n/g, '</p><p>') + '</p>'
          }
        }
      })
    },
    checkLogin() {
      let login = false
      if (!this.isLogin) {
        this.showLoginDialog = true
      } else if (!this.roleId) {
        this.showServerRoleDialog = true
      } else {
        login = true
      }
      return login
    }
  }
}
</script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
img[src=''], img:not([src]) {
  opacity:0
}
.warpbg {
  margin: 0px;
  padding: 0px;
  height: 40.40rem;
  width: 100%;
  background: url('../../assets/activity/redpacket/bg.jpg') top center no-repeat;
  background-size: contain;
  position: relative;
}
.user_head {
  height: 0.4rem;
  width: 100%;
  top: 0.2rem;
  right: 0.2rem;
  position:absolute;
}
.btn_login {
  float: right;
  width: 1.0rem;
  height: 0.4rem;
  /* right: 0.2rem; */
  background: #3a1000;
  border: solid 0.02rem #974e00;
  color: rgb(249, 209, 87);
  font-size: 0.3rem;
  line-height: 0.4rem;
  text-align: center;
  z-index: 10;
}
.server_role_text {
  float: right;
  width: 4.6rem;
  height: 0.4rem;
  background: #3a1000;
  border: solid 0.02rem #974e00;
  color: rgb(249, 209, 87);
  font-size: 0.3rem;
  line-height: 0.4rem;
  margin-right: 0.09rem;
  text-align: center;
}
.activity_name {
  width: 100%;
  height: 0.3rem;
  position: absolute;
  margin-top: 2.24rem;
  color: rgb(249, 209, 87);
  font-size: 0.3rem;
  text-align: center;
  line-height: 0.3rem;
  font-weight: bold;
}
/* 榜单 */
.box_rank {
  position: absolute;
  margin-top: 3.16rem;
  height: 8.02rem;
  background: url('../../assets/activity/redpacket/rank_bg.png') top center no-repeat;
  background-size: contain;
  width: 100%;
  z-index: 10;
}
/* 第一名 */
.cell_rank_1 {
  float: left;
  width: 2.40rem;
  height: 2.11rem;
  margin-top: 0.62rem;
  margin-left: 2.54rem;
  margin-right: 2.45rem;
}
.cell_rank_1_avatar {
  float: left;
  width: 1.04rem;
  height: 1.04rem;
  border-radius: 50%;
  margin-left: 0.69rem;
  margin-bottom: 0.15rem;
}
.cell_rank_1_server, .cell_rank_1_role {
  float: left;
  height: 0.3rem;
  width: 100%;
  font-size: 0.2rem;
  text-align: center;
  color: rgb(249, 209, 87);
  line-height: 0.3rem;
}
.cell_rank_1_comment_warp {
  height: 0.3rem;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.cell_rank_1_comment {
  height: 0.3rem;
  width: 100%;
  font-size: 0.2rem;
  /* text-align: center; */
  color: rgb(249, 209, 87);
  line-height: 0.3rem;
  margin-right: 0.03rem;
}

/* 榜单2-3名 */
.cell_rank_2-5 {
  float: left;
  width: 1.94rem;
  height: 1.72rem;
  margin-left: 1.17rem;
  margin-top: 0.87rem;
}
.cell_rank_dan {
  margin-left: 1.21rem;
}
.cell_rank_2-5_avatar {
  float: left;
  width: 0.83rem;
  height: 0.83rem;
  border-radius: 50%;
  margin-left: 0.57rem;
  margin-bottom: 0.14rem;
}
.cell_rank_2-5_server, .cell_rank_2-5_role {
  float: left;
  height: 0.25rem;
  width: 100%;
  font-size: 0.2rem;
  text-align: center;
  color: rgb(249, 209, 87);
  line-height: 0.25rem;
  white-space: nowrap;
}
.cell_rank_2-5_comment_warp {
  height: 0.25rem;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.cell_rank_2-5_comment {
  /* float: left; */
  height: 0.25rem;
  width: 100%;
  font-size: 0.2rem;
  /* text-align: center; */
  color: rgb(249, 209, 87);
  line-height: 0.25rem;
  white-space: nowrap;
  margin-right: 0.03rem;
}
.cell_rank_4-5 {
  margin-top: 0.74rem;
}

.login_popup {
  width: 5.08rem;
}
.login_dialog {
  width: 5.08rem;
}
.login_dialog .van-dialog__header {
  padding-top: 0.18rem
}
.login_dialog .van-popup__close-icon--top-right {
  top: 0.16rem;
  right: 0.16rem;
}
.login_form_title .van-cell__title{ 
    text-align: center;
}
.server_role_dialog {
  width: 4.26rem;
}


.frame_redpacket {
  position:absolute;
  margin-top: 11.32rem;
  width: 100%;
  height: 5.72rem;
  background: url('../../assets/activity/redpacket/redpacket_bg.gif') top center no-repeat;
  background-size: contain;
}
.box_prop_top {
  width: 6.01rem;
  height: 0.78rem;
  margin-top: 0.63rem;
  margin-left: 0.75rem;
}
/* 水平横排道具 */
.cell_prop_h {
  float: left;
  width: 1.69rem;
  height: 0.78rem;
  /* background: url('../../assets/activity/redpacket/prop_bg_1.png') top center no-repeat;
  background-size: contain; */
  margin-right: 0.47rem;

  /* position: relative; */
}

.cell_prop_h_last {
  margin-right: 0rem;
}

/* 中间层 START */
.box_redpacket_mid {
  width: 6.01rem;
  height: 2.91rem;
  margin-left: 0.75rem;
}
/* 左侧 */
.box_prop_left, .box_prop_right {
  float: left;
  width: 1.69rem;
  height: 2.80rem;
}
/* 垂直排列道具 */
.cell_prop_v {
  /* width: 1.12rem;
  height: 0.94rem;
  background: url('../../assets/activity/redpacket/prop_bg_2.png') top center no-repeat;
  background-size: contain; */
  /* margin-bottom: 0.16rem; */

  width: 1.69rem;
  height: 0.78rem;
  /* background: url('../../assets/activity/redpacket/prop_bg_1.png') top center no-repeat;
  background-size: contain; */
  margin-top: 0.47rem;
  position: relative;
}

/* 抽红包 */
.box_redpacket_draw {
  float: left;
  width: 2.63rem;
  height: 2.80rem;
}

/* 红包 */
.box_redpacket {
  width: 1.49rem;
  height: 1.95rem;
  background: url('../../assets/activity/redpacket/redpacket.png') top center no-repeat;
  background-size: contain;
  margin-left: 0.59rem;
  margin-top: 0.23rem;
}

.box_drawable {
  width: 2.22rem;
  height: 0.44rem;
  margin-left: 0.22rem;
}

.box_drawable_count {
  float: left;
  width: 1.08rem;
  height: 0.44rem;
}
.box_drawable_count_text {
  font-size: 0.20em;
  line-height: 0.44rem;
  white-space: nowrap;
  transform: scaleX(0.8) scaleY(0.8);
  left: -0.15rem;
  position: relative;
}

.btn_refresh_drawable_times {
  float: left;
  background: url('../../assets/activity/redpacket/btn_refresh_drawable.png') top center no-repeat;
  background-size: contain;
  width: 1.11rem;
  height: 0.44rem;
  margin-left: 0.03rem;
}
/* 中间层 END */

/* 底层 */
.box_prop_bottom {
  width: 6.01rem;
  height: 0.78rem;
  margin-left: 0.75rem;
}

.prop_img, .cell_last_prop_img {
  float: left;
  width: 0.6rem;
  height: 0.6rem;
  margin-left: 0.05rem;
  margin-top: 0.09rem;
}
.prop_name_right, .cell_last_prop_name {
  color: white;
  float: left;
  font-size: 0.20rem;
  height: 0.78rem;
  /* 不缩放 */
  width: 1.04rem;
  margin-top: 0.09rem;
  /* 缩放 */
  /* -webkit-transform: scaleX(0.8) scaleY(0.8);
  transform: scaleX(0.8) scaleY(0.8);
  width: 1.25rem;
  position:absolute;
  left: 32%; */
  
}

.prop_name_bottom {
  color: white;
  font-size: 0.25rem;
  width: 1.12rem;
  height: 0.28rem;
  float: left;

}
.prop_drew {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  background: grey;
}

.frame_drew {
  z-index: 999;
  background: url('../../assets/activity/redpacket/drew_bg.png') top center no-repeat;
  background-size: contain;
  width: 100%;
  height: 2.64rem;
  position: absolute;
  margin-top: -3.80rem ;
}

.box_drew_prop {
  width: 1.69rem;
  height: 0.78rem;
  margin-top: 0.82rem;
  margin-left: 2.91rem;
}

.box_drew_prop .drew_prop_img {
  float: left;
  width: 0.64rem;
  height: 0.64rem;
  margin-top: 0.06rem;
  margin-left: 0.02rem;
}
.box_drew_prop .drew_prop_name {
  float: left;
  color: white;
  float: left;
  font-size: 0.20rem;
  height: 0.78rem;
  /* 不缩放 */
  width: 1rem;
  margin-top: 0.06rem;
}

.box_drew_cdkey {
  width: 2.60rem;
  height: 0.25rem;
  margin-top: 0.2rem;
  margin-left: 2.45rem;
}
.box_drew_cdkey .drew_cdkey_text {
  float: left;
  background:white;
  width: 1.94rem;
  height: 100%;
  font-size: 0.2rem;
  line-height: 100%;
}
.box_drew_cdkey .drew_cdkey_btn_copy {
  float: left;
  height: 100%;
  width: 0.63rem;
  margin-left: 0.03rem;
}
.box_drew_confirm {
  height: 0.41rem;
  width: 1.25rem;
  margin-left: 3.15rem;
  margin-top: 0.07rem;
}

/* 最终奖励 */
.frame_last_prop {
  background: url('../../assets/activity/redpacket/last_prop_bg.gif') top center no-repeat;
  background-size: contain;
  position:absolute;
  width: 100%;
  height: 1.81rem;
  margin-top: 17.04rem;
}
.box_last_prop {
  width: 1.69rem;
  height: 0.79rem;
  margin-left: 1.25rem;
  margin-top: 0.64rem;
  float: left;
}
.box_last_prop_confirm {
  float: left;
  width: 1.25rem;
  height: 0.41rem;
  margin-left: 0.19rem;
  margin-top: 0.81rem;
  background: url('../../assets/activity/redpacket/last_prop_confirm.png') top center no-repeat;
  background-size: contain;
}
.box_last_prop_drew_count {
  float: left;
  background: black;
  width: 1.71rem;
  height: 0.31rem;
  margin-left: 0.10rem;
  margin-top: 0.91rem;
  font-size: 0.2rem;
  color: white;
  text-align: center;
  line-height: 0.31rem;
}

/* 用户已获得奖励 */
.frame_user_prop {
  width: 100%;
  height: 4.63rem;
  position:absolute;
  margin-top: 19.01rem;
  background: url('../../assets/activity/redpacket/user_prop_bg.png') top center no-repeat;
  background-size: contain;
}
.box_user_prop {
  width: 4.62rem;
  height: 3.57rem;
  margin-left: 1.47rem;
  margin-top: 0.74rem;
  padding-top: 0.12rem;
  overflow:auto;
}
.cell_user_prop {
  width: 3rem;
  height: 0.3rem;
  margin-left: 0.2rem;
  margin-bottom: 0.07rem;
}
.cell_user_prop_name {
  float: left;
  width: 2.21rem;
  height: 0.3rem;
  font-size: 0.2rem;
  line-height: 0.3rem;
  color: white;
}
.cell_user_prop_btn_show {
  float: left;
  width: 0.64rem;
  height: 0.3rem;
  margin-left: 0.15rem;
  font-size: 0.2rem;
}
.cell_user_prop .van-button--normal {
   padding: 0;
}
.frame_user_prop_cdkey {
  width: 3.27rem;
  height: 1.55rem;
  margin-left: 2.13rem;
  background: url('../../assets/activity/redpacket/user_prop_cdkey_bg.png') top center no-repeat;
  background-size: contain;
  margin-top: -2.88rem;
  z-index: 999;
  position: absolute;
}
.box_cdkey {
  width: 2.34rem;
  height: 0.23rem;
  margin-left: 0.51rem;
  margin-top: 0.58rem;
}
.cell_cdkey_text {
  width: 1.75rem;
  height: 0.23rem;
  font-size: 0.2rem;
  line-height: 0.2rem;
  float: left;
}
.cell_cdkey_btn_copy {
  margin-left: 0.03rem;
  width: 0.56rem;
  height: 0.23rem;
  float: left;
}
.box_cdkey_btn_confirm {
  width: 1.25rem;
  height: 0.41rem;
  margin-top: 0.15rem;
  margin-left: 1rem;
}

.frame_gift {
  margin-top: 24.87rem;
  position:absolute;
  width: 100%;
}
.box_gift {
  height: 0.9rem;
  width: 7.5rem;
  margin-bottom: 0.24rem;
  margin-left: 0.2rem;
}
.cell_gift {
  float: left;
  height: 0.9rem;
  width: 1.75rem;
}
.cell_gift_prop_img {
  float: left;
  height: 0.5rem;
  width: 0.5rem;
}

.cell_gift_prop_name {
  float: left;
  color: white;
  font-size: 0.2rem;
  width: 1.25rem;
}

.box_desc {
  position:absolute;
  margin-top: 30.81rem;
  margin-left: 0.2rem;
  /* width:100%; */
  font-size: 0.25rem;
  color: rgb(249, 209, 87);
}
.cell_desc_title {
  width: 100%;
}
.cell_desc_text {
  font-size: 0.2rem;
}
.box_desc p {
  margin: 0.15rem auto;
}


</style>